import {
  BookingConfig,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../BookingWidget/bookingSteps.interface';
import {
  BookingStepsContextState,
  BookingSubStepsQuotationForm,
  BookingSubStepsQuotationInformation,
} from '../../../context/bookingContext/bookingContext.types';
import getInsurancePayload from '../../../shared/helpers/getInsurancePayload.helper';
import { BookingQuoteResponse } from '../../BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import { Subscriber } from '../../../shared/interfaces/subscriber.interfaces';
import getBeneficiariesArray from '../../../shared/helpers/beneficiaries.helper';
import { BookingDataResponse } from '../../BookingWidget/BookingManagment/bookingManagment.interface';
import {
  InsuranceDataEnum,
  InsuranceEnum,
  InsurancePayload,
} from '../../../shared/interfaces/insurance.interface';
import { DecodedSessionToken } from '../../../context/sessionContext/sessionContext.interface';

const getInformationPersonal = (
  quotationInformationForm: BookingSubStepsQuotationInformation,
): Partial<Subscriber> => {
  const personalInformation =
    quotationInformationForm[BookingSubStepValueEnum.InformationPersonal];

  const {
    firstname = '',
    lastname = '',
    email,
    phone,
    birthdate,
  } = personalInformation || {};
  return {
    firstName: firstname,
    lastName: lastname,
    ...(email && { email }),
    ...(phone && { mobilePhone: phone, phone }),
    ...(birthdate && { birthdate }),
  };
};

const getPayloadForQuotationProposal = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  bookingQuoteResponse: BookingQuoteResponse | null,
  bookingDataResponse: BookingDataResponse | null = null,
  deleteProductSpecificFields = false,
  decodedSessionToken: DecodedSessionToken | null = null,
  bookingReferenceId: string | null = null,
): string => {
  const quotationForm: BookingSubStepsQuotationForm =
    bookingSteps[BookingStepValueEnum.QuotationForm];
  const quotationInformationForm: BookingSubStepsQuotationInformation =
    bookingSteps[BookingStepValueEnum.QuotationInformation];
  const insurance: InsurancePayload = getInsurancePayload(
    bookingSteps,
    bookingConfigData,
    bookingQuoteResponse,
    bookingReferenceId,
    decodedSessionToken,
  );

  if (deleteProductSpecificFields) {
    delete insurance[InsuranceEnum.InsuranceData][InsuranceDataEnum.Excess];
    delete insurance[InsuranceEnum.InsuranceData][
      InsuranceDataEnum.CancellationLimit
    ];
  }

  const params = {
    insurance,
    promotion: {
      promotionCode: quotationForm[BookingSubStepValueEnum.PromoCode],
    },
    subscriber: {
      preferredLanguage: localStorage.getItem('language') || '',
      // @TODO only for FCTG tests
      countryCode: 'au',
      ...getInformationPersonal(quotationInformationForm),
    },
    beneficiaries: getBeneficiariesArray(
      bookingSteps,
      bookingConfigData,
      bookingDataResponse,
    ),
  };
  return JSON.stringify(params);
};

const stringToArrayWithOptions = (input: string): (string | null)[] => {
  const result: (string | null)[] = input.split('|').map((value) => {
    if (value === '') {
      return null;
    }
    return value;
  });

  return result;
};

const arrayWithOptionsToString = (input: { [key: number]: string }): string => {
  const values = Object.values(input);
  return values.map((v) => (v === '|' ? '' : v)).join('|');
};

export {
  getPayloadForQuotationProposal,
  stringToArrayWithOptions,
  arrayWithOptionsToString,
};
