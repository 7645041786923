import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import QuotationElementBlockPrice from '../../QuotationsBlockShare/QuotationPriceElement/QuotationElementBlockPrice';
import { usePriceSummaryLoading } from '../../../context/priceSummaryContext/priceSummaryContext';

const PriceSummaryTax: FC = () => {
  const { t } = useTranslation();
  const { price, netPrice, taxGst, taxStampDuty } = usePriceSummaryLoading();

  const isSectionVisible = useMemo(
    () =>
      price > 0 &&
      netPrice !== undefined &&
      netPrice > 0 &&
      ((taxGst !== undefined && taxGst > 0) ||
        (taxStampDuty !== undefined && taxStampDuty > 0)),
    [price, netPrice, taxGst, taxStampDuty],
  );
  return (
    isSectionVisible && (
      <>
        <Box className="gst-stampduty-container">
          <QuotationElementBlockPrice
            product={[
              {
                productName:
                  t('BOOKING.QUOTATION_SUMMARY_BLOCK.totalBeforeTax') || 'GST',
                amount: netPrice?.toString(),
              },
            ]}
          />

          <QuotationElementBlockPrice
            fontWeightLabel="normal"
            product={[
              {
                productName: t('BOOKING.QUOTATION_SUMMARY_BLOCK.gst') || 'GST',
                amount: (taxGst as number).toString(),
              },
            ]}
          />

          <QuotationElementBlockPrice
            fontWeightLabel="normal"
            product={[
              {
                productName:
                  t('BOOKING.QUOTATION_SUMMARY_BLOCK.stampDuty') ||
                  'Stamp Duty',
                amount: (taxStampDuty as number).toString(),
              },
            ]}
          />
        </Box>
        <Divider sx={{ margin: '0.5rem 0' }} />
      </>
    )
  );
};

export default PriceSummaryTax;
