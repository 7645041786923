import { QuotePlugAndSell } from '../../../shared/interfaces/plugAndSell/quote.interface';
import { Product as ProductI } from '../useFetchProposalsConf';

interface Option {
  value: string;
  label: string;
}

interface GenericLimit {
  code: string;
  default: Option;
  options: Option[];
}

type CancellationLimit = GenericLimit;
type Excess = GenericLimit;
type MaxTripDuration = GenericLimit;

interface Limits<T> {
  [key: string]: T[];
}

type CancellationLimits = Limits<CancellationLimit>;
type Excesses = Limits<Excess>;
type MaxTripDurations = Limits<MaxTripDuration>;

interface Addons {
  disabledForCatalogs: string[];
}

interface AdditionalDataProposal {
  cancellationLimits: CancellationLimits;
  excess: Excesses;
  addons: Addons;
  maxTripDuration: MaxTripDurations;
}

enum BookingQuoteResponseQuoteProductEnum {
  Version = 'version',
  Status = 'status',
  BookingUuid = 'bookingUuid',
  BookingRefNumber = 'bookingRefNumber',
  BackLink = 'backLink',
  PSCatalogId = 'PSCatalogId',
  PSResponsePayload = 'PSResponsePayload',
  Product = 'product',
  CreationDate = 'creationDate',
  ExpirationDate = 'expirationDate',
}

interface BookingQuoteResponseQuoteProduct {
  [BookingQuoteResponseQuoteProductEnum.Version]: string;
  [BookingQuoteResponseQuoteProductEnum.Status]: string;
  [BookingQuoteResponseQuoteProductEnum.BookingUuid]: string;
  [BookingQuoteResponseQuoteProductEnum.BookingRefNumber]: string;
  [BookingQuoteResponseQuoteProductEnum.BackLink]: string;
  [BookingQuoteResponseQuoteProductEnum.PSCatalogId]: string;
  [BookingQuoteResponseQuoteProductEnum.PSResponsePayload]: QuotePlugAndSell;
  [BookingQuoteResponseQuoteProductEnum.Product]: ProductI;
  [BookingQuoteResponseQuoteProductEnum.CreationDate]: string;
  [BookingQuoteResponseQuoteProductEnum.ExpirationDate]: string;
}

export {
  AdditionalDataProposal,
  CancellationLimit,
  Excess,
  CancellationLimits,
  Excesses,
  MaxTripDuration,
  Option,
  BookingQuoteResponseQuoteProductEnum,
  BookingQuoteResponseQuoteProduct,
};
