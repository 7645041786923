// empty value is required at the end of the array, because there should be CFAR, but currently it is not available
export const AddonsPossibleValues = ['WINTERSPORTS', 'CRUISE', 'VALUABLES', ''];

export const OptionsPossibleValues = ['0', '150', '250', '500'];

export const CancellationLimitPossibleValues = [
  '1000',
  '2000',
  '5000',
  '10000',
  '15000',
  '20000',
  '25000',
  '50000',
  'Unlimited',
];
