import React, { useContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';

import '../QuotationsBlockShare/QuotationSummaryBlock.scss';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../BookingWidget/bookingSteps.interface';
import QuotationElementInformation from '../QuotationsBlockShare/QuotationInformationElement/QuotationElementInformation';
import {
  BookingManagementEnum,
  Companion,
  CompanionEnum,
} from '../BookingWidget/BookingManagment/bookingManagment.interface';
import useApi from '../../shared/services/api.service';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import { LoadingContext } from '../../context/loadingContext/loadingContext';
import { AgentConfigContext } from '../../context/agentContext/agentConfigContext';
import { BookingDataContext } from '../../context/bookingDataContext/bookingDataContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import './QuotationTripSummaryBlock.scss';
import { AppEvents, emit$ } from '../../shared/app-events';
import { useDiscountDialog } from '../../context/discountContext/discountContext';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import useGetTravelers from './helpers/useGetTravelers';
import SelectPromotion from '../QuotationFormWeb/SelectPromotion';
import canUsePromotion from '../../shared/helpers/canUsePromotion';
import { sendInteractionEvent } from '../../shared/services/gtm/gtm.service';
import { InteractionTypes } from '../../shared/interfaces/gtm.interface';
import { useSessionContext } from '../../context/sessionContext/sessionContext';

export interface QuotationTripSummaryBlockProps {
  nextStep: (step?: number) => void;
}

const QuotationTripSummaryBlock: React.FC<QuotationTripSummaryBlockProps> = ({
  nextStep,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [open, setOpen] = useState(!isMobile);
  const { setIsDiscountDialog, discountStatus } = useDiscountDialog();
  const { decodedSessionToken } = useSessionContext();
  const { bookingSteps, update } = useContext(BookingContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);
  const { isAgentView } = useContext(AgentConfigContext);
  const promoCode =
    bookingSteps[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.PromoCode
    ];
  const [selectedPromoCode, setSelectedPromoCode] = useState(
    bookingSteps[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.PromoCode
    ] || '',
  );
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter].cm360Endpoint;
  const { bookingDataResponse } = useContext(BookingDataContext);
  const dateFormat = bookingConfigData[BookingConfigEnum.BirthdayFormat];
  const { getTravelers } = useGetTravelers();

  const showPromotionSection =
    isAgentView &&
    bookingSteps.currentStep !== 'quotationRecapDeclarations' &&
    !promoCode;
  const shouldShowEditButton =
    bookingSteps.currentStep !== 'quotationRecapDeclarations';
  const showDiscountButton =
    isAgentView &&
    bookingSteps.currentStep !== 'quotationRecapDeclarations' &&
    !canUsePromotion(bookingConfigData);

  const handleCollapseClick = (): void => {
    if (isMobile) {
      setOpen(!open);
    }
  };

  const handleCollapseKey = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ): void => {
    if (isMobile) {
      if (event.key === 'ArrowUp') {
        setOpen(false);
      } else if (event.key === 'ArrowDown') {
        setOpen(true);
      }
    }
  };

  // TRAVELLERS

  const deleteTravellerScreening = (index: number): Promise<void> => {
    const selectedCompanion: Companion =
      bookingDataResponse![BookingManagementEnum.Companions]![index];
    const url = `${apiCM360Url}/medical-screenings/${
      selectedCompanion[CompanionEnum.Id]
    }`;
    return API.delete(url)
      .then((res: AxiosResponse) => {
        if (res.status !== 200) {
          showNotification('unexpectedError', 'error', false);
        } else {
          const updatedMedicalScreening = [
            ...bookingSteps[BookingStepValueEnum.QuotationInformation]
              .medicalScreening,
          ];
          updatedMedicalScreening[index] = null;
          update(
            updatedMedicalScreening,
            BookingStepValueEnum.QuotationInformation,
            BookingSubStepValueEnum.MedicalScreening,
          );
        }
      })
      .catch(() => {
        setIsLoading(false);
        showNotification('unexpectedError', 'error', false);
      });
  };

  const editHandler = async (): Promise<void> => {
    emit$(AppEvents.SAVE_PERSONAL_INFORMATION_FORM);
    const companions = bookingDataResponse?.[BookingManagementEnum.Companions];
    if (companions) {
      try {
        setIsLoading(true);
        const deletionRequests = companions?.map((c, i) =>
          deleteTravellerScreening(i),
        );
        await Promise.all(deletionRequests);
      } catch (error) {
        showNotification('updatingData', 'error', false);
      } finally {
        setIsLoading(false);
      }
    }

    update(
      [],
      BookingStepValueEnum.QuotationInformation,
      BookingSubStepValueEnum.MedicalScreening,
    );
    update(
      null,
      BookingStepValueEnum.QuotationProposals,
      BookingSubStepValueEnum.Proposal,
    );
    sendInteractionEvent(InteractionTypes.CTAButtons, 'Edit Trip');
    nextStep(1);
  };

  useEffect(() => {
    if (promoCode === '') {
      setSelectedPromoCode('');
    }
  }, [promoCode]);

  return (
    <div
      data-testid="QuotationTripSummaryBlock"
      data-cy="bookingW_Quote_Trip_summary_section"
    >
      <Card className={isMobile ? 'summary-mobile' : 'summary-desktop'}>
        <CardContent
          sx={{
            padding: '0.2rem 0.4rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '.2rem',
          }}
        >
          <div
            className="summary-mobile-title"
            role="button"
            tabIndex={0}
            onClick={handleCollapseClick}
            onKeyDown={handleCollapseKey}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1.25rem',
                lineHeight: '1.5rem !important',
              }}
              gutterBottom
            >
              {t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.title')}
            </Typography>
          </div>
          <Divider sx={{ mb: '1rem' }} />
          <Box sx={{ mb: 1 }}>
            <QuotationElementInformation
              variant="trip"
              label={t(
                `BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.${BookingSubStepValueEnum.GeographicalZone}`,
              )}
              elements={[
                bookingSteps[BookingStepValueEnum.QuotationForm][
                  BookingSubStepValueEnum.GeographicalZone
                ]
                  .map((option) => option[BookingSubStepOptionEnum.Label])
                  .join(', '),
              ]}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <QuotationElementInformation
              variant="trip"
              label={t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.dates')}
            >
              <div>
                <b>
                  {dayjs(
                    bookingSteps.quotationForm[
                      BookingSubStepValueEnum.DepartureDate
                    ],
                  ).format(dateFormat)}
                </b>
                <span> {t('COMMON.to')} </span>
                <b>
                  {dayjs(
                    bookingSteps.quotationForm[
                      BookingSubStepValueEnum.ReturnDate
                    ],
                  ).format(dateFormat)}
                </b>
              </div>
            </QuotationElementInformation>
          </Box>

          <Box sx={{ mb: 1 }}>
            <QuotationElementInformation
              variant="trip"
              label={t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.travellers')}
              elements={getTravelers}
            />
          </Box>

          {showPromotionSection && canUsePromotion(bookingConfigData) && (
            <Box sx={{ mb: 1 }}>
              <span className="label">
                {t('BOOKING.QUOTATION_FORM.web.promotionCode')}
              </span>
              <SelectPromotion
                value={selectedPromoCode}
                onChange={(e) => {
                  setSelectedPromoCode(e.target.value);
                  update(
                    e.target.value,
                    BookingStepValueEnum.QuotationForm,
                    BookingSubStepValueEnum.PromoCode,
                  );
                }}
                bookingConfigData={bookingConfigData}
                t={t}
              />
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: '.2rem' }}>
            {decodedSessionToken?.advanceSales &&
              showDiscountButton &&
              !discountStatus &&
              bookingSteps[BookingStepValueEnum.QuotationProposals][
                BookingSubStepValueEnum.Proposal
              ] && (
                <Button
                  variant="outlined"
                  size="small"
                  className="button grow"
                  onClick={() => setIsDiscountDialog(true)}
                >
                  {t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.discount')}
                </Button>
              )}

            {shouldShowEditButton && (
              <Button
                size="small"
                variant="outlined"
                className="button button-edit grow"
                sx={{ flexGrow: 1 }}
                onClick={editHandler}
                data-testid="bookingW_Quote_edit_btn"
                data-cy="bookingW_Quote_edit_btn"
              >
                {t('BOOKING.QUOTATION_TRIP_SUMMARY_BLOCK.edit')}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default QuotationTripSummaryBlock;
