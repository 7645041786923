import React, { useContext, useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { BookingQuoteContext } from '../../context/quoteContext/quoteContext';
import queryUrlBuilder from '../../shared/services/query-url-builder';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import {
  getBookingExternalId,
  getQuoteIdFromBookingDataConfig,
  getTravelConnectUrlFromBookingConfig,
  TravelConnectUrlBuilderProps,
} from './helio-utils';
import { BookingDataContext } from '../../context/bookingDataContext/bookingDataContext';

import './QuotationSummaryHelioBlock.scss';
import QuotationSummaryCard from '../QuotationsBlockShare/QuotationSummaryCard';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import {
  BookingDataEnum,
  BookingDataResponse,
  BookingManagementEnum,
} from '../BookingWidget/BookingManagment/bookingManagment.interface';
import { LoadingContext } from '../../context/loadingContext/loadingContext';
import ErrorLoggingService from '../../shared/services/errorlogging.service';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import useFlowSpecificFactory from '../QuotationRecapDeclarations/helpers/useFlowSpecificFactory';
import {
  AppEvents,
  emit$,
  subscribe$,
  unsubscribe$,
} from '../../shared/app-events';
import {
  BookingConfigEnum,
  BookingStepEnum,
  BookingStepValueEnum,
} from '../BookingWidget/bookingSteps.interface';
import { CacheDataContext } from '../../context/cacheContext/cacheContext';

const QuotationSummaryHelioBlock: React.FC = () => {
  const { bookingQuoteResponse: bookingQuote } =
    useContext(BookingQuoteContext);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { bookingDataResponse } = useContext(BookingDataContext);
  const { saveQuoteAndBookingDataAction, bookingSteps } =
    useContext(BookingContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { showNotification } = useContext(NotificationContext);
  const { isPolicyModificationFlow } = useFlowSpecificFactory();
  const { store } = useContext(CacheDataContext);

  const currentStep = store.currentStep
    ? bookingConfigData[BookingConfigEnum.BookingSteps][store.currentStep]
    : null;

  const isRecapDeclarationStepActive =
    currentStep?.[BookingStepEnum.KeyName] === 'quotationRecapDeclarations';

  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const redirectToHelio = useMemo((): string | null => {
    if (!bookingQuote) return null;

    const travellerConnectBaseUrl: string | null =
      getTravelConnectUrlFromBookingConfig(bookingConfigData);

    if (!travellerConnectBaseUrl)
      throw new Error(
        '[redirectToHelio] bookingConfigData.travelerConnectUrl is null',
      );

    const quoteId = getQuoteIdFromBookingDataConfig(bookingQuote);

    if (!quoteId)
      throw new Error(
        '[redirectToHelio] bookingConfigData.travelerConnectUrl is null',
      );

    return queryUrlBuilder<TravelConnectUrlBuilderProps>(
      travellerConnectBaseUrl,
      {
        bookingUuid:
          bookingDataResponse?.[BookingManagementEnum.BookingData][
            BookingDataEnum.Id
          ] || '',
        ref: getBookingExternalId(bookingDataResponse),
      },
    ).href;
  }, [bookingConfigData, bookingQuote, bookingDataResponse]);

  const handleHelioRedirect = (): void => {
    const isInIframe = window.self !== window.top;
    if (!redirectToHelio) {
      return;
    }

    if (isInIframe) {
      window.parent.postMessage(
        { type: 'REDIRECT_TO', redirectLink: redirectToHelio },
        '*',
      );
    } else {
      window.location.href = redirectToHelio;
    }
    unsubscribe$(AppEvents.PERSONAL_INFORMATION_SAVED, handleHelioRedirect);
  };

  const redirectToHelioClick = (): void => {
    setIsLoading(true);
    if (
      bookingSteps[BookingStepValueEnum.CurrentStep] ===
      BookingStepValueEnum.QuotationInformation
    ) {
      subscribe$(AppEvents.PERSONAL_INFORMATION_SAVED, handleHelioRedirect);
      emit$(AppEvents.SAVE_PERSONAL_INFORMATION_FORM);
      return;
    }

    saveQuoteAndBookingDataAction()
      .then(handleHelioRedirect)
      .catch((err) => {
        errorService.log('getInitialCustomersData', err);
        showNotification('unexpectedErrorDuringRedirect', 'error', false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isPolicyModificationFlow) {
    return null;
  }

  return (
    <QuotationSummaryCard
      collapseCustomCssClass="collapse-row-helio"
      mobileTitle={t('BOOKING.QUOTATION_ADD_TO_HELIO.mobileTitle')}
    >
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: '1rem',
          lineHeight: '1.25rem',
        }}
      >
        {t('BOOKING.QUOTATION_ADD_TO_HELIO.title')}
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '1rem',
          lineHeight: '1.25rem',
        }}
      >
        {t('BOOKING.QUOTATION_ADD_TO_HELIO.description')}
      </Typography>
      <Typography
        variant="body1"
        color="textPrimary"
        sx={{
          lineHeight: '1.6rem',
          fontSize: '0.8rem',
          margin: '.5rem 0',
          color: 'var(--dark-secondary)',
        }}
      >
        {t('BOOKING.QUOTATION_ADD_TO_HELIO.helioAgreement')}
      </Typography>
      {redirectToHelio && (
        <Button
          sx={{ mt: '0.625rem' }}
          size="small"
          className="button"
          onClick={redirectToHelioClick}
          variant="outlined"
        >
          {isRecapDeclarationStepActive
            ? t('BOOKING.QUOTATION_ADD_TO_HELIO.redirectButtonWithAgreement')
            : t('BOOKING.QUOTATION_ADD_TO_HELIO.redirectButton')}
        </Button>
      )}
    </QuotationSummaryCard>
  );
};

export default QuotationSummaryHelioBlock;
